

///тут мы через фетч получаем данные 

function JSN(param) {

    //set default value - type of reguest 
    if (param!==1)        {
        param=0;
    }

   
    const credentials = btoa("test:test");
    const  auth = { "Authorization" : `Basic ${credentials}` };

    const response =  fetch(
        "https://importapi.modul.spb.ru/api/vessel/" + param ,  { headers : auth }
      );

        return response;

}



export default JSN;