import './App.css';
import React, {useState ,useEffect} from "react";
import { MapContainer, TileLayer, Marker, Popup ,GeoJSON } from 'react-leaflet';
import L from 'leaflet';
//import { Icon } from "leaflet";

import Markers from './markers';
import MarkersWay  from './markerWay';

//import JSN from './getApiData'




function FilterJSON (data , fid ){

  //console.log (data);
  //console.log (fid);
      if (data.features === undefined) return null ; 
      const json2 = data.features ; 

    const  newJson = json2.filter(function(e){
      return e.properties.ID_Vessel === fid;
    });


    //console.log (newJson);
    return newJson;
}


function Rmap(props) {

 // const [vessel_track, setTrackedVessel] = useState(0);

 const [vessel_data, setTrackedVessel] = useState(props.vessel_data);


///console.log ( new Date().toLocaleTimeString());
 //vessel_track={vessel_track} vessel_data={vessel_data} /> 

   const data = props.data; ///Это дата с последней координатой  каждого судна
   const vdata = props.vessel_data; ///Это дата с последней координатой  каждого судна
   const classNameLF = props.classNameLF;
   
   //var  vessel_data = FilterJSON ( vdata ,props.vessel_track );
/*
  console.log("vdata");
  console.log(data);
  console.log("vessel_data");
  console.log(vessel_data);
  */

  useEffect(
    () => {
     // console.log("vt");
    //  console.log(props.vessel_track);
      setTrackedVessel ( FilterJSON ( vdata ,props.vessel_track));
    },[props.vessel_track]
  )

  function handleChange (vesselID){
    //setTrackedVessel (vesselID)

    setTrackedVessel ( FilterJSON ( vdata ,vesselID));

   /// console.log (vesselID);
  }


  return (
    <div>    <div  className = "overlay hide" >   <h1>  {data.type} </h1> </div>
      <MapContainer className = {classNameLF}    center={[20.4, -8.7]} zoom={3} scrollWheelZoom={true}>
  <TileLayer
    //url="https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=e0f3a72609e84de8beb48908a6e66cdb"
    url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
  />
  
  


  <Markers   jsn={data}  onClick={handleChange}   />
  <MarkersWay  jsn={vessel_data}  />
   
</MapContainer>
</div>

  );
}

export default Rmap;
