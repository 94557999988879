import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import PageOne from './PageOne' 
import PageTwo  from './PageTwo '
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';





ReactDOM.render(
  <React.StrictMode>


<BrowserRouter>
      <Routes>
        <Route path="*" element={<PageOne />} />
        <Route path="framed" element={<PageTwo />} />
      </Routes>
    </BrowserRouter>


  </React.StrictMode>,
  document.getElementById('root')
);

 
