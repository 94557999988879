
import './App.css';
//import React, {useState ,useEffect} from "react";
import { MapContainer, TileLayer, Marker, Popup ,Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { Icon } from "leaflet";
import {getdateTimestring} from './utils'




function rev(arr){
    return  [arr[1] , arr[0]];
 }

 const icon = new Icon({
    iconUrl:  "/icons8-ship-30.png",
    iconSize: [25, 25]
  });

  const iconRed = new Icon({
    iconUrl:  "/icons8-ship-red.png",
    iconSize: [25, 25]
  });


 


export default function Markers( props) {
  

  let  arr = props.jsn.features;
  const onClick = props.onClick;

 // console.log(arr);
 if (arr === undefined)
    return null;

  return (      
    <center>
      {  arr.map((gps, i) => (                  
         <Marker
         key= {"mark100" + i}
         position= {rev(gps.geometry.coordinates)}         
         icon={gps.properties.needtracking ==1 ? iconRed : icon}         
         eventHandlers={{
          click: (e) => {
           // console.log('marker clicked ' + gps.properties.ID_Vessel  , e);
            onClick(gps.properties.ID_Vessel);
          },
        }}
       >

<Tooltip direction="bottom" offset={[0, 2]} opacity={0.55} permanent> <b>{gps.properties.Name_Vessel}</b></Tooltip>

         <Popup> Судно : { gps.properties.Name_Vessel}  дата   { getdateTimestring (gps.properties.dte_last_event)}   <br/>
                  Координаты : lat {gps.geometry.coordinates[0]} lon {gps.geometry.coordinates[1]}  <br/>
                  Направление { gps.properties.destination}  из { gps.properties.last_port} 
                   </Popup>



        </Marker>        
      ))}
    </center>
  )
}


  