
import './App.css';
//import React, {useState ,useEffect} from "react";
import { MapContainer, TileLayer, Marker, Popup ,Circle } from 'react-leaflet';
import L from 'leaflet';
import { Icon } from "leaflet";
import {getdateTimestring} from './utils'



/*
Рисуем путь
*/


const icon = new Icon({
  iconUrl:  "/round.svg",
  iconSize: [55, 55]
});

function rev(arr){
    return  [arr[1] , arr[0]];
 }

export default function MarkersWay(props) {
  
  //console.log ("props@-++");
  //console.log (props);
  if (props.jsn == undefined){
    return null;
  }

  let  arr  = props.jsn;//.features;

 // console.log(arr);
 if (arr === undefined){
    return null;
 }

 if (Object.keys(arr).length ===0)
 {
  return null;
 } 

  //

  return (      
    <center>
      {  arr.map((gps, i) => (                  
         <Marker
         key= {"mark1000" + i}
         position= {rev(gps.geometry.coordinates)}     
         icon={icon}    
       >
  
         <Popup> Событие судна    { gps.properties.Name_Vessel}   от   { getdateTimestring (gps.properties.dte_last_event)}  <br/>
                  Координаты lat {gps.geometry.coordinates[0]}  lon  {gps.geometry.coordinates[1]}              

          </Popup>



        </Marker>        
      ))}
    </center>
  )
}


  