import './App.css';
import React, {useState , useEffect} from "react";
//import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import JSN from './getApiData'
import {getdateTimestring} from './utils'

import Rmap from './rmap';

//import * as parkData from "./data/skateboard-parks.json";

const  ComponentList  = ({ onChange ,data} ) =>
{
  
  const showAlert = (vessel) => {

    onChange(vessel); //пока только показываем судно на которое бьудем показывать трек 
  }

  if (data.features === undefined) return null ; 


  const arr = data.features;
  //console.log ("props.data @");
  //console.log (arr);
 
return  (
<div>
  {arr.map((gps, i) => (          

    <div className="card" key={"XX" + i}>
    <div className="card-header">
      Vessel name   {gps.properties.Name_Vessel}    
    </div>
    <div className="card-body">
      <h5 className="card-title">Lat  {gps.geometry.coordinates[0]}  Lon   {gps.geometry.coordinates[1]}  </h5>
      <p className="card-text">Destination  {gps.properties.destination}  from    {gps.properties.last_port}   <br/>      
       Sources  " {gps.properties.source} "       date: <span> {getdateTimestring(gps.properties.dte_last_event)}   </span>
       <button  className="btn btn-secondary btn-sm" 
      onClick={() => {
        showAlert(gps.properties.ID_Vessel);
      }}
      >
        Show Route</button>
        </p>
     
    </div>
</div> 
   
 ))}
</div>
);
}



function PageTwo() {

  const [data, setData] = useState({});
  const [vessel_track, setTrackedVessel] = useState(0);
  
  //data of vessel sails
  const [vessel_data, setTrackedVesselData] = useState({});

  const handleChange = (value) => {    
      setTrackedVessel(value);
  //    console.log ("btn");
  }

  useEffect(() => {
    (async () => {                
        JSN(1).then(async response => {
          
            if (!response.ok) {
                                
                const error =  response.statusText;               
                return Promise.reject(error);
            }
            const data = await response.json();            
            //console.log(data.type);
            setData(data);                              
        })
        .catch(error => {
           // this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });

        JSN(0).then(async response => {
          
          if (!response.ok) {
                              
              const error =  response.statusText;               
              return Promise.reject(error);
          }
          const vesseldata = await response.json();            
          //console.log(data.type);
          setTrackedVesselData (vesseldata);                     
      })
      .catch(error => {
         // this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });

    }
    
    )();
  }, []);

    //console.log (data);

  return (
    <div>

      <div className="block  w80 ">
      <Rmap data={data}  vessel_track={vessel_track} vessel_data={vessel_data} classNameLF  = "leaflet-container2"  />
    </div>

  <div className="block tabletext w20 TableTTX2 " id="TableTTX2"  >

    <ComponentList data={data}  onChange={handleChange}  /> 

  </div>
</div>
  );
}

export default PageTwo ;
